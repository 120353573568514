.mc-page-content {
    position: relative;
}

.mc-page-content .movie-city-dec-1 {
    position: absolute;
    width: auto;
    height: 280px;
    z-index: 10;
    left: -12%;
}

.mc-page-content .movie-city-dec-2 {
    position: absolute;
    right: -15%;
    height: 160px;
    z-index: 10;
    width: auto;
    transform: rotate(15deg);
}

.mc-page-content .movie-city-dec-3 {
    position: absolute;
    height: 500px;
    width: auto;
    z-index: -1;
    right: 38%;
    opacity: 0.15;
    top: 25%;
}

@media only screen and (max-width: 1440px) {
    .mc-page-content .movie-city-dec-1 {
        height: 240px;
    }

    .mc-page-content .movie-city-dec-2 {
        height: 140px;
    }
}

@media only screen and (max-width: 1024px) {
    .mc-page-content .movie-city-dec-1 {
        height: 200px;
    }

    .mc-page-content .movie-city-dec-2 {
        height: 120px;
    }
}

@media only screen and (max-width: 768px) {
    .mc-page-content .movie-city-dec-1 {
        height: 160px;
    }

    .mc-page-content .movie-city-dec-2 {
        height: 100px;
        bottom: 20%;
    }

    .mc-page-content .movie-city-dec-3 {
        right: 18%;
    }
}

@media only screen and (max-width: 450px) {
    .mc-page-content .movie-city-dec-1 {
        height: 140px;
    }

    .mc-page-content .movie-city-dec-2 {
        height: 100px;
        bottom: 25%;
        right: -15%;
    }
}

@media only screen and (max-width: 426px) {
    .mc-page-content .movie-city-dec-1 {
        height: 140px;
    }

    .mc-page-content .movie-city-dec-2 {
        height: 100px;
        bottom: 25%;
        right: -15%;
    }
}

@media only screen and (max-width: 376px) {
    .mc-page-content .movie-city-dec-1 {
        height: 120px;
    }

    .mc-page-content .movie-city-dec-2 {
        height: 80px;
        bottom: 24%;
        right: -15%;
    }
}

@media only screen and (max-width: 321px) {
    .mc-page-content .movie-city-dec-1 {
        height: 100px;
    }

    .mc-page-content .movie-city-dec-2 {
        height: 70px;
        bottom: 24%;
        right: -15%;
    }
}