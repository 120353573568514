.hero-slider-card {
    width: 100%;
    height: 100%;
}

.hero-slider-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    animation: opacity 1s ease-out forwards;
}

@keyframes opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.hero-slider-slide::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0.5;
    background: linear-gradient(0deg, rgba(0,0,0, 0.5) 0%, rgba(0,0,0, 0.5) 50%, rgba(0,0,0, 0.7) 100%);
}

.hero-slider-slide img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.hero-slider-text {
    position: absolute;
    width: auto;
    height: 100%;
    margin: 0 100px;
    z-index: 10;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.hero-slider-text h1 {
    margin-bottom: 40px;
    font-size: 4rem !important;
}

.hero-slider-text h3 {
    color: var(--main_color);
}

@media only screen and (max-width: 1440px) {
    .hero-slider-text {
        margin: 0 80px;
    }
}

@media only screen and (max-width: 1024px) {
    .hero-slider-text {
        margin: 0 50px;
    }

    .hero-slider-text h1 {
        font-size: 3rem !important;
    }
}

@media only screen and (max-width: 768px) {
    .hero-slider-text {
        margin: 0 25px;
    }

    .hero-slider-text h1 {
        font-size: 2.4rem
        !important;
    }
}

@media only screen and (max-width: 450px) {
    .hero-slider-text {
        margin: 0 15px;
    }

    .hero-slider-text h1 {
        font-size: 2rem !important;
    }
}

@media only screen and (max-width: 426px) {
    .hero-slider-text {
        margin: 0 15px;
    }

    .hero-slider-text h1 {
        font-size: 2rem !important;
    }
}

@media only screen and (max-width: 376px) {
    .hero-slider-text {
        margin: 0 10px;
    }
}

@media only screen and (max-width: 321px) {
    .hero-slider-text {
        margin: 0 5px;
    }
}