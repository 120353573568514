.home-hero-slider {
    width: 100%;
    height: 100vh;
    max-height: 1100px;
    position: relative;
    overflow: hidden;
}

.home-hero-slider-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
}

.home-hero-slider-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: var(--main_color);
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 25;
    opacity: 0.5;
    transition: opacity .3s ease;
}

.home-hero-slider-btn:hover {
    opacity: 1;
}

.home-hero-slider-btn:hover > svg {
    color: var(--gold_color);
}

.home-hero-slider-btn svg {
    color: var(--text_color);
    transition: color .3s ease;
}

.home-slider-prev {
    left: 30px;
}

.home-slider-next {
    right: 30px;
}

/* CARD */
.home-hero-slide-card {
    width: 100%;
    height: 100%;
}

.home-hero-slider-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    animation: opacity 1s ease-out forwards;
}

@keyframes opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.home-hero-slider-slide::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0.5;
    background: linear-gradient(0deg, rgba(0,0,0, 0.5) 0%, rgba(0,0,0, 0.5) 50%, rgba(0,0,0, 0.7) 100%);
}

.home-hero-slider-slide img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.home-hero-slide-overlay {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: var(--main_color);
}

.home-hero-slide-content {
    width: auto;
    height: 100%;
    margin: 0 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.home-hero-slide-content h1 {
    margin-bottom: 60px;
    width: auto;
    pointer-events: none;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    animation: titleAnimation .3s ease-in forwards;
    animation-delay: .5s;
    transform: translateY(-100%);
    opacity: 0;
}

@keyframes titleAnimation {
    0%{
        transform: translateY(-20px);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}

.home-hero-slide-content .home-slider-card-desc {
    max-width: 60%;
    opacity: 0;
    animation: descAnimation .3s ease-in-out forwards;
    animation-delay: .7s;
    margin-bottom: 80px;
}

.home-hero-slide-content button {
    width: 300px;
    height: 60px;
    border-radius: 5px;
    color: var(--main_color);
    background-color: transparent;
    border: 2px solid var(--main_color);
    cursor: pointer;
    font-size: 1.2rem;
    text-transform: uppercase;
    animation: btnAnimation .3s ease-in forwards;
    animation-delay: .5s;
    transform: translateY(20px);
    opacity: 0;
}

@keyframes btnAnimation {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes descAnimation {
    0%{
        opacity: 0;
        transform: translateX(-100px);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}

@media only screen and (max-width: 1440px) {
    .home-slider-prev {
        left: 20px;
    }

    .home-slider-next {
        right: 20px;
    }

    .home-hero-slide-content {
        margin: 0 80px;
    }
}

@media only screen and (max-width: 1024px) {
    .home-slider-prev {
        left: 5px;
    }

    .home-slider-next {
        right: 5px;
    }

    .home-hero-slide-content {
        margin: 0 50px;
    }
}

@media only screen and (max-width: 768px) {
    .home-slider-prev {
        left: 0;
    }

    .home-slider-next {
        right: 0;
    }

    .home-hero-slider-btn {
        width: 30px;
        height: 30px;
    }

    .home-hero-slider-btn svg {
        font-size: 1rem;
    }

    .home-hero-slide-content {
        margin: 0 25px;
    }

    .home-hero-slide-content .home-slider-card-desc {
        max-width: 100%;
    }
}

@media only screen and (max-width: 450px) {
    .home-hero-slide-content {
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 426px) {
    .home-hero-slide-content {
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 376px) {
    .home-hero-slide-content {
        margin: 0 10px;
    }
}

@media only screen and (max-width: 321px) {
    .home-hero-slide-content {
        margin: 0 5px;
    }
}