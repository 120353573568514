.hero-slider {
    width: 100%;
    height: calc(100vh - 100px);
    max-height: 1000px;
    position: relative;
    overflow: hidden;
}

.hero-slider-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
}

.hero-slider-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: var(--main_color);
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 25;
    opacity: 0.5;
    transition: opacity .3s ease;
}

.hero-slider-btn:hover {
    opacity: 1;
}

.hero-slider-btn:hover > svg {
    color: var(--gold_color);
}

.hero-slider-btn svg {
    color: var(--main_text_color);
    transition: color .3s ease;
}

.hs-prev {
    left: 30px;
}

.hs-next {
    right: 30px;
}

@media only screen and (max-width: 1440px) {
    .hs-prev {
        left: 20px;
    }

    .hs-next {
        right: 20px;
    }
}

@media only screen and (max-width: 1024px) {
    .hs-prev {
        left: 5px;
    }

    .hs-next {
        right: 5px;
    }
}

@media only screen and (max-width: 768px) {
    .hs-prev {
        left: 0;
    }

    .hs-next {
        right: 0;
    }

    .hero-slider-btn {
        width: 30px;
        height: 30px;
    }

    .hero-slider-btn svg {
        font-size: 1rem;
    }
}