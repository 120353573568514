.hero-section {
    width: 100%;
    height: 100vh;
    position: relative;
    max-height: 800px;
}

.hero-section.show {
    opacity: 1;
}

.hero-section video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
    position: relative;
    pointer-events: none;
}

.hero-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom right , rgba(0,0,0,0.4), transparent), linear-gradient(to top left , rgba(0,0,0,0.5), transparent);
    z-index: 1;
}

.hero-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-content-items {
    margin: 0 100px;
}

.hero-content-items h2.show {
    transform: translateX(0);
    opacity: 1;
}

.hero-content-items h2 {
    transition-delay: .2s;
    margin-bottom: 30px;
    font-weight: bold;
    color: #fff;
}

.hero-content-items h1 {
    margin-bottom: 60px !important;
}

.hero-content-items h1.show {
    transform: translateX(0);
    opacity: 1;
}

.hero-content-items p {
    max-width: 50%;
    color: var(--main_color);
    margin-bottom: 70px;
    transition-delay: .4s;
    font-weight: bold;
}

.hero-content-items .movie-city-p {
    margin-bottom: 0;
}

.hero-content-items p.show {
    transform: translateX(0);
    opacity: 1;
    transition-delay: .4s;
}

.hero-content-items .hero-btn-anim.show {
    transform: translateX(0);
    opacity: 1;
    transition-delay: .6s;
}

.hero-content-items .hero-btn {
    background-color: var(--main_color);
    padding: 10px 25px;
    text-decoration: none;
    color: var(--hover_color);
    border-radius: 5px;
    transition: color .3s ease, background .3s ease;
}

.hero-content-items .hero-btn-pink {
    display: none;
}

.hero-content-items .hero-btn:hover {
    background-color: var(--main_text_color);
    color: var(--main_color);
}

.home-page-content-items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media only screen and (max-width: 1440px) {
    .hero-content-items {
        margin: 80px;
    }
}

@media only screen and (max-width: 1024px) {
    .hero-content-items p {
        max-width: 80%;
    }

    .hero-content-items {
        margin: 0 50px;
    }
}

@media only screen and (max-width: 768px) {
    .hero-content-items {
        margin: 0 25px;
    }

    .hero-content-items p {
        max-width: 100%;
    }
}

@media only screen and (max-width: 450px) {
    .hero-content-items {
        margin: 0 15px;
    }

    .hero-content-items h1 {
        margin-bottom: 40px;
    }

    .hero-content-items p {
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 426px) {
    .hero-content-items {
        margin: 0 15px;
    }

    .hero-content-items h1 {
        margin-bottom: 40px;
    }

    .hero-content-items p {
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 376px) {
    .hero-content-items {
        margin: 0 10px;
    }
}

@media only screen and (max-width: 321px) {
    .hero-content-items {
        margin: 0 5px;
    }
}