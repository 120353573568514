.about-us-vectors-component {
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
}

.about-us-vectors-component img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .2;
}

.about-us-vectors-cc {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, #fff, transparent 35%);
}

.about-us-vectors {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-us-vectors-container {
    position: relative;
    width: 500px;
    height: 500px;
    border: 2px solid var(--main_text_color);
    border-radius: 50%;
}

.about-us-vectors-icon {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -50%;
}

.about-us-vectors-box {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main_text_color);
    transition: all .5s ease;
    box-shadow: 0 0 0 4px var(--hover_color), 0 0 0 6px var(--main_text_color);
    transform-origin: 280px;
    transform: rotate(calc(360deg/12 * var(--i)));
    cursor: pointer;
    z-index: 100;
    overflow: hidden;
}

.about-us-vectors-box.active {
    box-shadow: 0 0 0 4px var(--hover_color), 0 0 0 12px var(--gold_color);
}

.about-us-vectors-box span {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(calc(-360deg/12 * var(--i)));
}

.about-us-vectors-box span svg {
    color: var(--main_color) !important;
    font-size: 1.4rem;
}

.about-us-vectors-desc {
    position: absolute;
    inset: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-us-vectors-desc::before {
    content: '';
    position: absolute;
    inset: 60px;
    border: 4px solid transparent;
    border-left: 4px solid var(--main_text_color);
    border-right: 4px solid var(--hover_color);
    border-radius: 50%;
    animation: animate_01 5s linear infinite;
    z-index: 1;
    pointer-events: none;
}

@keyframes animate_01 {
    0% {
        rotate: 0deg;
    }
    100% {
        rotate: 360deg;
    }
}

.about-us-vectors-desc::after {
    content: '';
    position: absolute;
    inset: 100px;
    border: 4px solid transparent;
    border-left: 4px solid var(--gold_color);
    border-right: 4px solid var(--hover_color);
    border-radius: 50%;
    animation: animate_02 2.5s linear infinite;
    z-index: 1;
    pointer-events: none;
}

@keyframes animate_02 {
    0% {
        rotate: 360deg;
    }
    100% {
        rotate: 0;
    }
}

.about-us-vectors-content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.about-us-vectors-content span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-us-vectors-content span svg {
    font-size: 2.6rem;
    color: var(--hover_color);
}

.about-us-vectors-content h3 {
    margin: 1rem 0;
}

@media only screen and (max-width: 768px) {
    .about-us-vectors-container {
        width: 380px;
        height: 380px;
    }

    .about-us-vectors-container .about-us-vectors-icon .about-us-vectors-box {
        transform-origin: 215px;
    }

    .about-us-vectors-container .about-us-vectors-icon .about-us-vectors-box {
        width: 50px;
        height: 50px;
    }
}

@media only screen and (max-width: 450px) {
    .about-us-vectors-container {
        width: 320px;
        height: 320px;
    }

    .about-us-vectors-container .about-us-vectors-icon .about-us-vectors-box {
        transform-origin: 185px;
    }

    .about-us-vectors-container .about-us-vectors-icon .about-us-vectors-box {
        width: 50px;
        height: 50px;
    }

    .about-us-vectors-desc::before {
        inset: 50px;
    }

    .about-us-vectors-desc::after {
        inset: 70px;
    }
}

@media only screen and (max-width: 426px) {
    .about-us-vectors-container {
        width: 320px;
        height: 320px;
    }

    .about-us-vectors-container .about-us-vectors-icon .about-us-vectors-box {
        transform-origin: 185px;
    }

    .about-us-vectors-container .about-us-vectors-icon .about-us-vectors-box {
        width: 50px;
        height: 50px;
    }

    .about-us-vectors-desc::before {
        inset: 50px;
    }

    .about-us-vectors-desc::after {
        inset: 70px;
    }
}

@media only screen and (max-width: 376px) {
    .about-us-vectors-container {
        width: 280px;
        height: 280px;
    }

    .about-us-vectors-container .about-us-vectors-icon .about-us-vectors-box {
        transform-origin: 160px;
    }

    .about-us-vectors-container .about-us-vectors-icon .about-us-vectors-box {
        width: 40px;
        height: 40px;
    }

    .about-us-vectors-desc::before {
        inset: 40px;
    }

    .about-us-vectors-desc::after {
        inset: 55px;
    }
}

@media only screen and (max-width: 321px) {

    .about-us-vectors-container {
        width: 240px;
        height: 240px;
    }

    .about-us-vectors-container .about-us-vectors-icon .about-us-vectors-box {
        transform-origin: 140px;
    }

    .about-us-vectors-container .about-us-vectors-icon .about-us-vectors-box {
        width: 40px;
        height: 40px;
    }

    .about-us-vectors-desc::before {
        inset: 35px;
    }

    .about-us-vectors-desc::after {
        display: none;
    }
}