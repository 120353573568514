.about-us-title {
    width: 100%;
    height: 300px;
    position: relative;
}

.about-us-title.show {
    opacity: 1;
}

.about-us-title img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
}

.about-us-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-us-overlay h1 {
    color: var(--main_color);
    text-align: center;
}