.movie-city-gallery {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.movie-city-gallery h1 {
    margin-bottom: 40px;
}

.movie-city-gallery-content {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 30px;
    column-gap: 10px;
}

.movie-city-gallery-card {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: relative;
}

.movie-city-gallery-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.movie-city-gallery-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1024px) {
    .movie-city-gallery-content {
        grid-template-columns: repeat(2, 1fr);
    }

    .movie-city-gallery {
        height: auto;
    }
}

@media only screen and (max-width: 450px) {
    .movie-city-gallery-content {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media only screen and (max-width: 426px) {
    .movie-city-gallery-content {
        grid-template-columns: repeat(1, 1fr);
    }
}