.gallery-card {
    height: 380px;
    background-color: var(--main_color);
    border-radius: 5px;
    scroll-snap-align: start;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery-card-inner {
    width: 95%;
    height: 100%;
}

.gallery-card .gallery-card-inner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gallery-card-preview {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.6);
}