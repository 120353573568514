.birthdays-component {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
    column-gap: 10px
}

.birthdays-btn {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
}

.birthdays-btn button {
    border: none;
    outline: none;
    width: 300px;
    height: auto;
    padding: 15px 0;
    text-transform: uppercase;
    background-color: var(--hover_color);
    color: var(--main_color);
    cursor: pointer;
}

.birthdays-btn button:disabled {
    pointer-events: none;
    background-color: var(--silver_color);
}

.birthdays-music {
    height: 380px;
    width: 100%;
}

.birthdays-music .birthdays-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.birthdays-music .birthdays-video source {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.birthdays-music .birthdays-video::-webkit-media-controls {
    opacity: 0;
    background-color: rgba(0,0,0,0.2);
    transition: all .3s ease;
}

.birthdays-music:hover .birthdays-video::-webkit-media-controls {
    opacity: 1;
}

@media only screen and (max-width: 768px) {
    .birthdays-component {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 450px) {
    .birthdays-component {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media only screen and (max-width: 426px) {
    .birthdays-component {
        grid-template-columns: repeat(1, 1fr);
    }
}