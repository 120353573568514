.weddings-component {
    width: 100%;
    height: auto;
    background-color: var(--main_text_color);
    position: relative;
    height: auto;
    margin: 2rem 0;
}

.weddings-component .decorative-img:first-child {
    width: 300px;
    height: 300px;
    position: absolute;
    left: -100px;
    top: -150px;
    z-index: 100;
}

.weddings-component .decorative-img:last-child {
    width: 300px;
    height: 300px;
    position: absolute;
    right: -140px;
    bottom: -120px;
    z-index: 100;
}

.weddings-component .decorative-img.show {
    opacity: 1;
    transition-delay: .2s;
}

.weddings-component-content {
    width: auto;
    height: 100%;
    margin: 0 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    column-gap: 10px;
    position: relative;
    padding: 3rem 0;
}

.weddings-component-column {
    width: 100%;
    height: 100%;
    position: relative;
}

.weddings-component-column img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
}

.weddings-component-column img.show {
    opacity: 1;
    transform: translateX(0);
    transition-delay: .2s;
}

.weddings-component-box {
    width: 100%;
    height: 300px;
    position: absolute;
    background-color: var(--main_color);
    top: 50%;
    right: -90%;
    transform: translateY(-50%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}


.weddings-component-box h3 {
    font-weight: bold;
}

.weddings-component-box.show {
    opacity: 1;
}

@media only screen and (max-width: 1520px) {
    .decorative-img {
        display: none;
    }
}

@media only screen and (max-width: 1440px) {
    .weddings-component-content {
        margin: 0 80px;
    }
}

@media only screen and (max-width: 1024px) {
    .weddings-component-content {
        grid-template-columns: repeat(1, 1fr);
        margin: 0 50px;
    }

    .weddings-component-column:nth-child(2) {
        display: none;
    }

    .weddings-component-box {
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0;
        border-radius: 0;
        background-color: rgba(0,0,0,0.6);
        padding: 0;
    }

    .weddings-component-box h3 {
        max-width: 700px;
        color: var(--main_color) !important;
        letter-spacing: 2px;
    }
}

@media only screen and (max-width: 768px) {
    .weddings-component-content {
        margin: 0 25px;
    }

    .weddings-component-column h3 {
        max-width: 80%;
        font-size: 0.9rem;
        letter-spacing: 1px;
    }
}

@media only screen and (max-width: 450px) {
    .weddings-component-content {
        margin: 0 15px;
    }

    .weddings-component-column h3 {
        max-width: 90%;
        font-size: 0.8rem;
        letter-spacing: 0;
    }
}

@media only screen and (max-width: 426px) {
    .weddings-component-content {
        margin: 0 15px;
    }

    .weddings-component-column h3 {
        max-width: 90%;
        font-size: 0.8rem;
        letter-spacing: 0;
    }
}

@media only screen and (max-width: 376px) {
    .weddings-component-content {
        margin: 0 10px;
    }

    .weddings-component-column h3 {
        max-width: 95%;
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 321px) {
    .weddings-component-content {
        margin: 0 5px;
    }
}