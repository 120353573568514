.navbar-section {
    width: 100%;
    height: auto;
    position: fixed;
    background-color: transparent;
    z-index: 9999;
    transition: background .5s ease;
    animation: navbarAnimation 1s;
}

@keyframes navbarAnimation {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.navbar-section.color {
    background-color: var(--main_color);
}

.navbar-content {
    margin: 0 100px;
}

/* NAVBAR RELATIVE */
.navbar-relative {
    position: sticky;
    width: 100%;
    height: auto;
    top: 0;
    background-color: var(--main_color);
    z-index: 9999;
    animation: navbarAnimation 1s;
}

.navbar-relative.fixed {
    position: fixed;
    top: 0;
}

/* NAVBAR TOP */
.navbar-top-content {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    border-bottom: 1px solid var(--main_text_color);
}

.navbar-svg {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    text-decoration: none;
    color: var(--main_text_color);
    background-color: transparent;
    border-radius: 8px;
    transition: all .5s ease;
}

.navbar-svg:hover {
    background-color: var(--main_text_color);
    color: var(--main_color);
}

.navbar-svg svg {
    font-size: 1.2rem;
}

.navbar-location svg , .navbar-number svg {
    margin-right: 10px;
}

.navbar-instagram, .navbar-facebook {
    margin-right: 10px;
}

.navbar-media {
    display: flex;
}

/* NAVBAR BOTTOM */
.navbar-bottom-content {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar-logo {
    width: auto;
    height: 40px;
}

.navbar-logo img {
    width: 100%;
    height: 40px;
}

.navbar-interactions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-interactions svg {
    font-size: 1.5rem !important;
}

.navbar-interactions .navbar-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 8px;
    transition: all .3s ease;
    background-color: transparent;
    transition: background .3s ease;
}

.navbar-number {
    margin-right: 10px;
}

.navbar-phone svg {
    margin-right: 10px;
}

.navbar-send {
    margin-right: 10px;
}

.navbar-interactions .navbar-menu svg {
    font-size: 1.2rem;
    color: var(--main_text_color);
    transition: color .3s ease;
}

.navbar-interactions .navbar-menu:hover {
    background-color: var(--main_text_color);
}

.navbar-interactions .navbar-menu:hover > svg {
    color: var(--main_color);
}

/* NAVBAR SIDEBAR */
.navbar-sidebar {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 425px;
    height: 100vh;
    background-color: var(--main_text_color);
    top: 0;
    right: 0;
    z-index: 100;
    clip-path: circle(0px at top right);
    transition: clip-path ease-in-out 1s .5s;
}

.navbar-sidebar img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.25;
}

.navbar-sidebar.appear {
    clip-path: circle(250% at top right);
}

.navbar-sidebar.appear a {
    animation: fadeIn cubic-bezier(.3, -0.01, .49, 1) .9s forwards;
    opacity: 0;
}

.navbar-sidebar.disappear a {
    animation: fadeOut cubic-bezier(.3, -0.01, .49, 1) .6s forwards;
    opacity: 1;
}

.navbar-sidebar.disappear {
    clip-path: circle(0px at top right);
}

.navbar-sidebar a {
    text-decoration: none;
    font-size: 1.2rem;
    margin: 15px 0;
    color: var(--main_color);
}

.navbar-sidebar a.active {
    color: var(--hover_color);
}

.sidebar-close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    margin: 15px;
    cursor: pointer;
}

.sidebar-close svg {
    color: var(--main_color);
    font-size: 1.2rem;
}

/* Navbar Animations */
.navbar-sidebar.appear a:nth-child(1) {animation-delay: .1;}
.navbar-sidebar.appear a:nth-child(2) {animation-delay: .2s;}
.navbar-sidebar.appear a:nth-child(3) {animation-delay: .3s;}
.navbar-sidebar.appear a:nth-child(4) {animation-delay: .4s;}
.navbar-sidebar.appear a:nth-child(5) {animation-delay: .5s;}
.navbar-sidebar.appear a:nth-child(6) {animation-delay: .6s;}
.navbar-sidebar.appear a:nth-child(7) {animation-delay: .7s;}
.navbar-sidebar.appear a:nth-child(8) {animation-delay: .8s;}
.navbar-sidebar.appear a:nth-child(9) {animation-delay: .9s;}
.navbar-sidebar.appear a:nth-child(10) {animation-delay: 1s;}
.navbar-sidebar.appear a:nth-child(11) {animation-delay: 1.1s;}
.navbar-sidebar.appear a:nth-child(12) {animation-delay: 1.2s;}
.navbar-sidebar.appear a:nth-child(13) {animation-delay: 1.3s;}

.navbar-sidebar.disappear a:nth-child(1) {animation-delay: 1.3s;}
.navbar-sidebar.disappear a:nth-child(2) {animation-delay: 1.2s;}
.navbar-sidebar.disappear a:nth-child(3) {animation-delay: 1.1s;}
.navbar-sidebar.disappear a:nth-child(4) {animation-delay: 1s;}
.navbar-sidebar.disappear a:nth-child(5) {animation-delay: .9s;}
.navbar-sidebar.disappear a:nth-child(6) {animation-delay: .8s;}
.navbar-sidebar.disappear a:nth-child(7) {animation-delay: .7s;}
.navbar-sidebar.disappear a:nth-child(8) {animation-delay: .6s;}
.navbar-sidebar.disappear a:nth-child(9) {animation-delay: .5s;}
.navbar-sidebar.disappear a:nth-child(10) {animation-delay: .4s;}
.navbar-sidebar.disappear a:nth-child(11) {animation-delay: .3s;}
.navbar-sidebar.disappear a:nth-child(12) {animation-delay: .2s;}
.navbar-sidebar.disappear a:nth-child(13) {animation-delay: .1s;}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateX(200px);
    }
    75% {
        opacity: .4;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateX(400px);
    }
}

@media only screen and (max-width: 1440px) {
    .navbar-content {
        margin: 0 80px;
    }
}

@media only screen and (max-width: 1024px) {
    .navbar-content {
        margin: 0 50px;
    }
}

@media only screen and (max-width: 768px) {
    .navbar-content {
        margin: 0 25px;
    }

    .navbar-svg {
        font-size: 0.9rem;
    }

    .navbar-phone span {
        display: none;
    }

    .navbar-phone svg  {
        margin-right: 0 !important;
    }
}

@media only screen and (max-width: 450px) {
    .navbar-content {
        margin: 0 15px;
    }

    .navbar-location span {
        display: none;
    }

    .navbar-location svg {
        margin-right: 0;
    }

    .navbar-sidebar {
        width: 100%;
    }
}

@media only screen and (max-width: 426px) {
    .navbar-content {
        margin: 0 15px;
    }

    .navbar-location span {
        display: none;
    }

    .navbar-location svg {
        margin-right: 0;
    }

    .navbar-sidebar {
        width: 100%;
    }
}

@media only screen and (max-width: 376px) {
    .navbar-content {
        margin: 0 10px;
    }
}

@media only screen and (max-width: 321px) {
    .navbar-content {
        margin: 0 5px;
    }
}