.seminars-slider {
    width: 100%;
    background-color: var(--main_text_color);
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
}

.seminars-circle-right {
    position: absolute;
    width: 1520px;
    height: 1520px;
    border-radius: 50%;
    right: -50%;
    overflow: hidden;
}

.seminars-circle-right.show {
    opacity: 1;
    transition-delay: .2s;
}

.seminars-circle-right img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.seminars-vector-content {
    width: 800px;
    height: auto;
    margin: 0 100px;
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    padding: 1rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.seminars-vector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1rem;
}

.seminars-vector h1:first-child {
    font-size: 2.5rem;
}

.seminars-vector h1:last-child {
    color: var(--main_color);
}

@media only screen and (max-width: 1024px) {
    .seminars-circle-right {
        width: 100%;
        border-radius: 0;
        right: 0;
    }

    .seminars-vector-content {
        width: 100%;
        height: auto;
    }
}

@media only screen and (max-width: 768px) {
    .seminars-vector h1:first-child {
        font-size: 2.3rem;
    }
    
    .seminars-vector h1:last-child {
        color: var(--main_color);
        font-size: 1.4rem;
    }
}

@media only screen and (max-width: 450px) {
    .seminars-vector h1:first-child {
        font-size: 2rem;
    }
    
    .seminars-vector h1:last-child {
        color: var(--main_color);
        font-size: 1.1rem;
    }
}

@media only screen and (max-width: 426px) {
    .seminars-vector h1:first-child {
        font-size: 2rem;
    }
    
    .seminars-vector h1:last-child {
        color: var(--main_color);
        font-size: 1.1rem;
    }
}