@import url('https://fonts.googleapis.com/css2?family=Montagu+Slab:opsz@16..144&family=Montserrat&family=Raleway&family=Water+Brush&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sansita+Swashed:wght@300&display=swap');

:root {
  --main_color: #fff;
  --main_text_color: #e5d5b6;
  --hover_color: #B7AA91;
  --gold_color: #e4ad48;
  --pink_color: #eeafb0;
  --silver_color: #bebdba;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Sansita Swashed', cursive;
}

body {
  overflow-x: hidden;
}

h1 {
  font-family: 'Water Brush', cursive;
  color: var(--gold_color);
  font-size: 3rem;
}

h2, h3 {
  font-family: 'Sansita Swashed', cursive;
  color: var(--hover_color);
}

h3 {
  font-weight: 100;
}

* {
  margin: 0;
  padding: 0;
}

.page-container {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.page-container::-webkit-scrollbar {
  display: none;
}

.page-content {
  margin: 0 100px;
}

.hidden {
  transition: all .6s ease;
  opacity: 0;
}

.hidden-left {
  transition: all .6s ease;
  transform: translateX(-100%);
  opacity: 0;
}

.hidden-right {
  transition: all .6s ease;
  transform: translateX(100%);
  opacity: 0;
}

@media only screen and (max-width: 1440px) {
  .page-content {
    margin: 0 80px;
  }
}

@media only screen and (max-width: 1024px) {
  h1 {
    font-size: 2.7em;
  }

  h2 {
    font-size: 1.17em;
  }

  h3 {
    font-size: 1em;
  }

  .page-content {
    margin: 0 50px;
  }
}

@media only screen and (max-width: 768px) {
  .page-content {
    margin: 0 25px;
  }

  h3 {
    font-size: 0.9rem;
  }

  h1 {
    font-size: 2.4rem;
  }
}

@media only screen and (max-width: 426px) {
  .page-content {
    margin: 0 15px;
  }
}

@media only screen and (max-width: 376px) {
  .page-content {
    margin: 0 10px;
  }
}

@media only screen and (max-width: 321px) {
  .page-content {
    margin: 0 5px;
  }
}

@media (prefers-reduced-motion) {
  .hidden {
    transition: none;
  }
}