.layout-text-right {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: auto;
    padding: 3rem 0;
    column-gap: 10px;
    row-gap: 20px;
    overflow-x: hidden;
}

.layout-text-right .layout-text-right-column {
    height: auto;
}

.layout-text-right-column:nth-child(1).show{
    opacity: 1;
    transform: translateX(0);
}

.layout-text-right-column:nth-child(2).show{
    opacity: 1;
    transform: translateX(0);
}

.layout-text-right .layout-text-right-column:nth-child(2) {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.layout-text-right .layout-text-right-column:nth-child(2) p {
    color: var(--hover_color);
    font-size: 1.6rem;
    text-align: right;
    font-weight: 600;
}

.layout-text-right .layout-text-right-column:nth-child(2) img {
    position: absolute;
    z-index: 5;
    width: 800px;
    height: 100%;
    opacity: .4;
}

.layout-text-right .layout-text-right-box {
    height: 480px;
    width: 100%;
    z-index: 10;
}

.layout-text-right .layout-text-right-box img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 1024px) {
    .layout-text-right .layout-text-right-column:nth-child(2) p {
        font-size: 1.3rem;
    }
}

@media only screen and (max-width: 768px) {
    .layout-text-right {
        grid-template-columns: repeat(1, 1fr);
    }

    .layout-text-right .layout-text-right-column .layout-text-right-box {
        height: 420px !important;
    }

    .layout-text-right .layout-text-right-column p {
        font-size: 1rem !important;
        text-align: center !important;
    }
}

@media only screen and (max-width: 450px) {
    .layout-text-right .layout-text-right-column p {
        text-align: left !important;
    }
}

@media only screen and (max-width: 426px) {
    .layout-text-right .layout-text-right-column p {
        text-align: left !important;
    }
}