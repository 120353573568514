.vectors-component {
    padding: 3rem 0;
}

.vectors-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    row-gap: 20px;
}

.vectors-grid.show {
    opacity: 1;
}

.vector-column {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    padding: 0 20px;
    text-decoration: none;
    border-radius: 5px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);
    transition: all .3s ease;
}

.vector-column:hover {
    box-shadow: 0 6px 6px 0 rgba(0,0,0,0.2);
    transform: translateY(-10px);
}

.vector-column span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.vector-column span svg {
    font-size: 3rem;
    color: var(--main_text_color);
}

@media only screen and (max-width: 1024px) {
    .vector-column span svg {
        font-size: 2.6rem;
    }
    
    .vector-column h2 {
        font-size: 1.5rem !important;
    }
}

@media only screen and (max-width: 768px) {
    .vectors-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .vector-column span svg {
        font-size: 2.3rem;
    }
    
    .vector-column h2 {
        font-size: 1.2rem !important;
    }
}

@media only screen and (max-width: 450px) {
    .vectors-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media only screen and (max-width: 426px) {
    .vectors-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}