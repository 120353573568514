.fg-band-component {
    width: 100%;
    height: auto;
    padding: 3rem 0;
    background-image: url('../../Assets/band.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    z-index: 1;
    overflow-x: hidden;
}

.fg-band-component.show {
    opacity: 1;
}

.fg-band-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(to bottom right, rgba(0,0,0,0.4), transparent), linear-gradient(to top left, rgba(0,0,0,0.4), transparent);
}

.fg-band-content {
    z-index: 25;
    width: auto;
    position: relative;
    height: auto;
    margin: 0 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
}

.fg-band-column {
    height: 480px;
}

.fg-band-column:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fg-band-column h1 {
    font-family: 'Water Brush', cursive !important;
    font-size: 48px !important;
    margin-bottom: 40px;
}

.fg-band-column h3 {
    color: var(--main_color) !important;
    margin-bottom: 40px;
}

.fg-band-column h1.show {
    opacity: 1;
    transform: translateX(0);
}

.fg-band-column h3.show {
    opacity: 1;
    transform: translateX(0);
    transition-delay: .2s;
}

.fg-band-column:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fg-band-column .circle {
    width: 480px;
    height: 480px;
    border-radius: 50%;
    background-color: var(--main_color);
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 1s ease-in-out;
    background-position: center center;
    object-fit: cover;
}

.fg-band-column .circle.show {
    opacity: 1;
    transform: translateX(0);
}

.fg-band-column:nth-child(1) a {
    width: 300px;
    height: 60px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--main_color);
    text-decoration: none;
    color: var(--main_color);
    font-size: 1.2rem;
}

.fg-band-column:nth-child(1) a.show {
    opacity: 1;
    transform: translateX(0);
    transition-delay: .4s;
}

@media only screen and (max-width: 1440px) {
    .fg-band-content {
        margin: 0 80px;
    }
}

@media only screen and (max-width: 1024px) {
    .fg-band-content {
        margin: 0 50px;
        grid-template-columns: repeat(1, 1fr);
    }

    .fg-band-content .fg-band-column:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
    }

    .fg-band-content .fg-band-column:nth-child(1) h1 {
        text-align: center;
    }

    .fg-band-content .fg-band-column:nth-child(1) h3 {
        text-align: center;
    }

    .fg-band-content .fg-band-column:nth-child(2) {
        height: auto;
    }

    .fg-band-content .fg-band-column:nth-child(2) .circle {
        border-radius: 20px;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .fg-band-content {
        margin: 0 25px;
    }
}

@media only screen and (max-width: 450px) {
    .fg-band-content {
        margin: 0 15px;
    }

    .fg-band-content .fg-band-column:nth-child(1) {
        align-items: center;
    }

    .fg-band-content .fg-band-column:nth-child(1) h3 {
        text-align: left;
    }

    .fg-band-content .fg-band-column:nth-child(2) .circle {
        height: 360px;
    }
}

@media only screen and (max-width: 426px) {
    .fg-band-content {
        margin: 0 15px;
    }

    .fg-band-content .fg-band-column:nth-child(1) {
        align-items: center;
    }

    .fg-band-content .fg-band-column:nth-child(1) h3 {
        text-align: left;
    }

    .fg-band-content .fg-band-column:nth-child(2) .circle {
        height: 360px;
    }
}

@media only screen and (max-width: 376px) {
    .fg-band-content {
        margin: 0 10px;
    }
}

@media only screen and (max-width: 321px) {
    .fg-band-content {
        margin: 0 5px;
    }
}