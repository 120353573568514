.full-gallery {
    height: auto;
    width: 100%;
    overflow-x: hidden;
}

.full-gallery-title.show {
    opacity: 1;
    transform: translateX(0);
}

.full-gallery-title h1 {
    text-align: center;
}

.full-gallery-title {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3rem 0;
}

.full-gallery-title a {
    width: 300px;
    height: 50px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    color: var(--main_text_color);
    background-color: var(--main_color);
    border: 2px solid var(--main_text_color);
    text-decoration: none;
    transition: all .3s ease;
}

.full-gallery-title a:hover {
    background-color: var(--main_text_color);
    color: var(--main_color);
}

@media only screen and (max-width: 768px) {
    .full-gallery-title {
        flex-direction: column;
    }

    .full-gallery-title h1 {
        margin-bottom: 1rem;
    }
}