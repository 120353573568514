.contact-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    height: auto;
    padding: 3rem 0;
    row-gap: 30px;
    overflow-x: hidden;
    box-sizing: border-box;
}

.contact-column {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.form .single-contact-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    margin-bottom: 15px;
}

.form .single-contact-input.show {
    opacity: 1;
    transform: translateX(0);
}

.form .single-contact-input:nth-child(2){
    transition-delay: .2s;
}

.form .single-contact-input:nth-child(3){
    transition-delay: .4s;
}

.form .single-contact-input:nth-child(4){
    transition-delay: .6s;
}

.form .single-contact-input:nth-child(5) {
    margin-bottom: 0;
}

.form button.show {
    opacity: 1;
    transform: translateX(0);
    transition-delay: .8s;
}

.form .single-contact-input label {
    color: var(--hover_color);
    font-size: 1rem;
    padding: 10px 0;
    text-transform: uppercase;
}

.form .single-contact-input input {
    width: 420px;
    height: 50px;
    padding: 0 10px;
    border: none;
    outline: none;
    border-radius: 10px;
    background-color: var(--main_text_color);
    color: var(--main_color);
    font-size: 1rem;
}

.form .single-contact-input input::placeholder {
    color: var(--main_color);
}

.form .single-contact-input textarea {
    width: 420px;
    height: 120px;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 10px;
    background-color: var(--main_text_color);
    color: var(--main_color);
    font-size: 1rem;
    resize: none;
}

.form .single-contact-input textarea::placeholder {
    color: var(--main_color);
}

.form button {
    width: 320px;
    padding: 15px;
    border: 2px solid var(--main_text_color);
    background-color: transparent;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 5px;
    color: var(--hover_color);
    transition: all .3s ease;
}

.form button:hover {
    background-color: var(--main_text_color);
    color: var(--main_color);
}

/* RIGHT SIDE */
.contact-column:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.contact-column h1 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    text-transform: uppercase;
    color: var(--main_text_color) !important;
}

.contact-column h2:nth-child(2) {
    margin-bottom: 30px;
}

.contact-column p {
    color: var(--hover_color);
    margin-bottom: 15px;
}

.contact-column h3 {
    margin-bottom: 20px;
}

.contact-text.show {
    transform: translateX(0);
    opacity: 1;
}

.contact-text:nth-child(2).show{
    transition-delay: .2s;
}

.contact-text:nth-child(3).show{
    transition-delay: .4s;
}

.contact-text:nth-child(4).show{
    transition-delay: .6s;
}

.contact-text:nth-child(5).show{
    transition-delay: .8s;
}

.contact-text:nth-child(6).show{
    transition-delay: 1s;
}

.error {
    color: #d33d33;
    opacity: 0;
    height: 19.2px;
    margin-top: 10px;
    transition: opacity .3s ease;
}

.error.visible {
    opacity: 1;
}


@media only screen and (max-width: 1024px) {
    .form .single-contact-input input {
        width: 320px;
    }

    .form .single-contact-input textarea {
        width: 320px;
    }
}

@media only screen and (max-width: 768px){
    .contact-form {
        grid-template-columns: repeat(1,1fr);
    }

    .contact-column {
        height: auto;
    }
}

@media only screen and (max-width: 450px){
    .contact-column:nth-child(2){
        text-align: left;
        align-items: flex-start;
        width: 100%;
    }

    .form .single-contact-input input {
        width: 375px;
    }

    .form .single-contact-input textarea {
        width: 375px;
    }

    .form button {
        width: 300px;
    }
}

@media only screen and (max-width: 426px){
    .contact-column:nth-child(2){
        text-align: left;
        align-items: flex-start;
        width: 100%;
    }

    .form .single-contact-input input {
        width: 375px;
    }

    .form .single-contact-input textarea {
        width: 375px;
    }

    .form button {
        width: 300px;
    }
}

@media only screen and (max-width: 376px) {
    .form .single-contact-input input {
        width: 320px;
    }

    .form .single-contact-input textarea {
        width: 320px;
    }

    .form button {
        width: 280px;
    }
}

@media only screen and (max-width: 321px) {
    .form .single-contact-input input {
        width: 260px;
    }

    .form .single-contact-input textarea {
        width: 260px;
    }

    .form button {
        width: 260px;
    }

    .error {
        font-size: 0.8rem;
        text-align: center;
    }

    .contact-column-2 h1 {
        font-size: 1.5rem;
    }
}