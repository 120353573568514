.preloader {
    width: 100%;
    height: 100vh;
    z-index: 99999;
    background-color: var(--main_text_color);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.preloader img {
    width: 200px;
    margin-bottom: 40px;
}

.preloader h3 {
    margin-bottom: 40px;
}

.preloader-dots {
    display: flex;
}

.preloader-dots span {
    width: 25px;
    height: 25px;
    margin: 0 25px;
    background-color: var(--main_color);
    border-radius: 50%;
    display: inline-block;
    animation-name: dots;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.preloader-dots span:nth-child(2){
    animation-delay: .4s;
}

.preloader-dots span:nth-child(3){
    animation-delay: .8s;
}

@keyframes dots {
    50% {
        opacity: 0;
        transform: scale(0.7) translateY(10px);
    }
}