.seminars-component {
    width: 100%;
    height: auto;
    background-color: var(--main_text_color);
    overflow: hidden;
    position: relative;
}

.seminars-component .sc-dec {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .25;
    object-fit: cover;
}

.seminars-component.show {
    opacity: 1;
}

.seminars-component-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 100px;
    row-gap: 30px;
    position: relative;
    z-index: 20;
    padding: 3rem 0;
}

.seminars-component-column {
    width: 100%;
    height: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.seminars-component-column:nth-child(1) {
    align-items: flex-start;
}

.seminars-component-column:nth-child(1) h1 {
    margin-bottom: 40px;
}

.seminars-component-column:nth-child(1) h1.show {
    opacity: 1;
    transform: translateX(0);
}

.seminars-component-column:nth-child(1) h3 {
    margin-bottom: 40px;
    color: var(--main_color);
}

.seminars-component-column:nth-child(1) h3.show {
    opacity: 1;
    transform: translateX(0);
    transition-delay: .2s;
}

.seminars-component-column:nth-child(1) .seminars-component-btn.show {
    opacity: 1;
    transform: translateX(0);
    transition-delay: .4s;
}

.seminars-component-column:nth-child(1) a {
    width: 300px;
    height: 60px;
    border: 2px solid var(--main_color);
    color: var(--main_color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    text-decoration: none;
    border-radius: 5px;
    transition: background .3s ease;
}

.seminars-component-column:nth-child(1) a:hover {
    background-color: var(--hover_color);
}

.seminars-component-column:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
}

.seminars-component-circle {
    width: 480px !important;
    height: 480px !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.seminars-component-circle img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.seminars-component-circle.show {
    opacity: 1;
    transform: translateX(0);
}

@media only screen and (max-width: 1440px) {
    .seminars-component-content {
        margin: 0 80px;
    }
}

@media only screen and (max-width: 1024px) {
    .seminars-component-content {
        margin: 0 50px;
        grid-template-columns: repeat(1, 1fr);
    }

    .seminars-component-column:nth-child(1) {
        height: auto;
        align-items: center;
        justify-content: center;
    }

    .seminars-component-column:nth-child(2) {
        height: auto;
        align-items: center;
        justify-content: center;
    }

    .seminars-component-column:nth-child(1) h3 {
        text-align: center;
    }

    .seminars-component-column:nth-child(2) .seminars-component-circle {
        width: 100% !important;
        border-radius: 20px;
    } 
}

@media only screen and (max-width: 768px) {
    .seminars-component-content {
        margin: 0 25px;
    }

    .seminars-component-column:nth-child(2) .seminars-component-circle {
        height: 360px !important;
    } 
}

@media only screen and (max-width: 450px) {
    .seminars-component-content {
        margin: 0 15px;
    }

    .seminars-component-column:nth-child(1) h3 {
        text-align: left;
    }
}

@media only screen and (max-width: 426px) {
    .seminars-component-content {
        margin: 0 15px;
    }

    .seminars-component-column:nth-child(1) h3 {
        text-align: left;
    }
}

@media only screen and (max-width: 376px) {
    .seminars-component-content {
        margin: 0 10px;
    }
}

@media only screen and (max-width: 321px) {
    .seminars-component-content {
        margin: 0 5px;
    }
}