.layout-text-left {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: auto;
    padding: 3rem 0;
    column-gap: 10px;
    row-gap: 20px;
    overflow-x: hidden;
}

.layout-text-left .layout-text-left-column {
    height: auto;
}

.layout-text-left .layout-text-left-column:nth-child(1) {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.layout-text-left .layout-text-left-column:nth-child(1).show {
    opacity: 1;
    transform: translateX(0);
}

.layout-text-left .layout-text-left-column:nth-child(2).show {
    opacity: 1;
    transform: translateX(0);
}

.layout-text-left .layout-text-left-column:nth-child(1) .pink {
    color: var(--pink_color);
    font-size: 1.6rem;
    letter-spacing: 1px;
}

.layout-text-left .layout-text-left-column:nth-child(1) p {
    color: var(--hover_color);
    font-size: 1.6rem;
    font-weight: 600;
}

.layout-text-left .layout-text-left-column:nth-child(1) img {
    position: absolute;
    z-index: 5;
    width: 800px;
    height: 100%;
    opacity: .4;
}

.layout-text-left .layout-text-left-box {
    height: 480px;
    width: 100%;
    z-index: 10;
}

.layout-text-left .layout-text-left-box img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 1024px) {
    .layout-text-left .layout-text-left-column:nth-child(1) p {
        font-size: 1.3rem;
    }
}

@media only screen and (max-width: 768px) {
    .layout-text-left {
        grid-template-columns: repeat(1, 1fr);
    }

    .layout-text-left .layout-text-left-column .layout-text-left-box {
        height: 420px !important;
    }

    .layout-text-left .layout-text-left-column p {
        font-size: 1rem !important;
    }
}