.apartman-hero-component {
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
    max-height: 1000px;
}

.apartman-hero-component.show {
    opacity: 1;
}

.apartman-hero-component img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.apartman-hero-overlay {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    position: absolute;
    top: 0;
    left: 0;
}

.apartman-hero-content {
    width: auto;
    height: 100%;
    position: relative;
    margin: 0 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.apartman-hero-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    row-gap: 20px;
    column-gap: 10px;
    height: 100%;
}

.apartman-hero-grid-column {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.apartman-hero-grid-column:nth-child(1) {
    text-align: left;
    align-items: flex-start;
}

.apartman-hero-grid-column:nth-child(1).show {
    opacity: 1;
    transform: translateX(0);
}

.apartman-hero-grid-column:nth-child(2).show {
    opacity: 1;
    transform: translateX(0);
}

.apartman-hero-grid-column:nth-child(1) h1 {
    margin-bottom: 40px;
}

.apartman-hero-grid-column:nth-child(1) p {
    color: var(--main_color);
}

.apartman-hero-grid-column:nth-child(2) .apartman-hero-box {
    width: 100%;
    height: 480px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
}

.apartman-hero-grid-column:nth-child(2) .apartman-hero-box .apartman-hero-box-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(0,0,0,0.4);
}

.apartman-hero-grid-column:nth-child(2) .apartman-hero-box .apartman-hero-box-content h2 {
    margin: 15px 0;
    color: var(--main_color);
    font-weight: 300;
}


.apartman-hero-grid-column:nth-child(2) .apartman-hero-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 1440px) {
    .apartman-hero-content {
        margin: 0 80px;
    }
}

@media only screen and (max-width: 1024px) {
    .apartman-hero-content {
        margin: 0 50px;
    }
}

@media only screen and (max-width: 768px) {
    .apartman-hero-content {
        margin: 0 25px;
    }

    .apartman-hero-grid {
        grid-template-columns: repeat(1, 1fr);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .apartman-hero-grid-column:nth-child(2) .apartman-hero-box {
        height: 320px;
    }

    .apartman-hero-grid-column:nth-child(1) {
        height: auto;
        text-align: center;
        align-items: center;
    }

    .apartman-hero-grid-column:nth-child(1) p {
        font-size: 0.9rem;
    }

    .apartman-hero-grid-column:nth-child(1) h1 {
        margin-bottom: 20px !important;
    }
}

@media only screen and (max-width: 450px) {
    .apartman-hero-content {
        margin: 0 15px;
    }

    .apartman-hero-grid-column:nth-child(1) p {
        font-size: 0.8rem;
    }

    .apartman-hero-grid-column:nth-child(1) h1 {
        margin-bottom: 15px !important;
    }
}

@media only screen and (max-width: 426px) {
    .apartman-hero-content {
        margin: 0 15px;
    }

    .apartman-hero-grid-column:nth-child(1) p {
        font-size: 0.8rem;
    }

    .apartman-hero-grid-column:nth-child(1) h1 {
        margin-bottom: 15px !important;
    }
}

@media only screen and (max-width: 376px) {
    .apartman-hero-content {
        margin: 0 10px;
    }
}

@media only screen and (max-width: 321px) {
    .apartman-hero-content {
        margin: 0 5px;
    }
}