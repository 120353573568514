:root {
    --i: 1
}

.home-vectors-component {
    width: 100%;
    height: 100vh;
    background-color: var(--main_color);
    max-height: 900px;
    position: relative;
}

.home-vectors-component img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    opacity: 0.4;
    position: relative;
}

.home-vectors-component-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to top, var(--main_color), transparent);
}

.home-vectors-content {
    margin: 0 100px;
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-vectors-container {
    position: relative;
    width: 500px;
    height: 500px;
    border: 2px solid var(--main_text_color);
    border-radius: 50%;
}

.home-vectors-container .home-vectors-icon {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -50%;
}

.home-vectors-container .home-vectors-icon .home-vectors-box {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main_text_color);
    transition: all .5s ease;
    box-shadow: 0 0 0 4px var(--hover_color), 0 0 0 6px var(--main_text_color);
    transform-origin: 280px;
    transform: rotate(calc(360deg/11 * var(--i)));
    cursor: pointer;
    z-index: 100;
    overflow: hidden;
}

.home-vectors-container .home-vectors-icon .home-vectors-box.active {
    box-shadow: 0 0 0 4px var(--hover_color), 0 0 0 12px var(--gold_color);
}

.home-vectors-container .home-vectors-icon .home-vectors-box span {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(calc(-360deg/11 * var(--i)));
}

.home-vectors-container .home-vectors-icon .home-vectors-box span svg {
    color: var(--main_color);
    font-size: 1.4rem;
}

.home-vectors-dec {
    position: absolute;
    inset: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-vectors-dec::before {
    content: '';
    position: absolute;
    inset: 60px;
    border: 4px solid transparent;
    border-left: 4px solid var(--main_text_color);
    border-right: 4px solid var(--hover_color);
    border-radius: 50%;
    animation: animate_01 7.5s linear infinite;
    z-index: 1;
    pointer-events: none;
}

@keyframes animate_01 {
    0% {
        rotate: 0deg;
    }
    100% {
        rotate: 360deg;
    }
}

.home-vectors-dec::after {
    content: '';
    position: absolute;
    inset: 100px;
    border: 4px solid transparent;
    border-left: 4px solid var(--gold_color);
    border-right: 4px solid var(--hover_color);
    border-radius: 50%;
    animation: animate_02 5s linear infinite;
    z-index: 1;
    pointer-events: none;
}

@keyframes animate_02 {
    0% {
        rotate: 360deg;
    }
    100% {
        rotate: 0;
    }
}

.home-vectors-vector-content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.home-vectors-vector-content span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-vectors-vector-content span svg {
    font-size: 2.6rem;
    color: var(--hover_color);
}

.home-vectors-vector-content h3 {
    margin: 1rem 0;
    font-weight: bold;
}

.home-vectors-vector-content a {
    color: var(--gold_color);
    font-weight: bold;
}

@media only screen and (max-width: 1440px) {
    .home-vectors-content {
        margin: 0 80px;
    }
}

@media only screen and (max-width: 1024px) {
    .home-vectors-content {
        margin: 0 50px;
    }
}

@media only screen and (max-width: 768px) {
    .home-vectors-content {
        margin: 0 25px;
    }

    .home-vectors-container {
        width: 380px;
        height: 380px;
    }

    .home-vectors-container .home-vectors-icon .home-vectors-box {
        transform-origin: 215px;
    }

    .home-vectors-container .home-vectors-icon .home-vectors-box {
        width: 50px;
        height: 50px;
    }
}

@media only screen and (max-width: 450px) {
    .home-vectors-content {
        margin: 0 15px;
    }

    .home-vectors-container {
        width: 320px;
        height: 320px;
    }

    .home-vectors-container .home-vectors-icon .home-vectors-box {
        transform-origin: 185px;
    }

    .home-vectors-container .home-vectors-icon .home-vectors-box {
        width: 50px;
        height: 50px;
    }

    .home-vectors-dec::before {
        inset: 50px;
    }

    .home-vectors-dec::after {
        inset: 70px;
    }

    .home-vectors-component img {
        opacity: .4;
    }
}

@media only screen and (max-width: 426px) {
    .home-vectors-content {
        margin: 0 15px;
    }

    .home-vectors-container {
        width: 320px;
        height: 320px;
    }

    .home-vectors-container .home-vectors-icon .home-vectors-box {
        transform-origin: 185px;
    }

    .home-vectors-container .home-vectors-icon .home-vectors-box {
        width: 50px;
        height: 50px;
    }

    .home-vectors-dec::before {
        inset: 50px;
    }

    .home-vectors-dec::after {
        inset: 70px;
    }

    .home-vectors-component img {
        opacity: .4;
    }
}

@media only screen and (max-width: 376px) {
    .home-vectors-content {
        margin: 0 10px;
    }

    .home-vectors-container {
        width: 280px;
        height: 280px;
    }

    .home-vectors-container .home-vectors-icon .home-vectors-box {
        transform-origin: 160px;
    }

    .home-vectors-container .home-vectors-icon .home-vectors-box {
        width: 40px;
        height: 40px;
    }

    .home-vectors-dec::before {
        inset: 40px;
    }

    .home-vectors-dec::after {
        inset: 55px;
    }
}

@media only screen and (max-width: 321px) {
    .home-vectors-content {
        margin: 0 5px;
    }

    .home-vectors-container {
        width: 240px;
        height: 240px;
    }

    .home-vectors-container .home-vectors-icon .home-vectors-box {
        transform-origin: 140px;
    }

    .home-vectors-container .home-vectors-icon .home-vectors-box {
        width: 40px;
        height: 40px;
    }

    .home-vectors-dec::before {
        inset: 35px;
    }

    .home-vectors-dec::after {
        display: none;
    }
}