.apartman-banner-component {
    width: 100%;
    height: 200px;
    background-color: var(--main_text_color);
    position: relative;
}

.apartman-banner-component.show {
    opacity: 1;
}

.apartman-banner-component img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .2;
}

.apartman-banner-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.1);
}

.apartman-banner-content {
    margin: 0 100px;
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.apartman-banner-content h3 {
    color: var(--main_color) !important;
}

@media only screen and (max-width: 1440px) {
    .apartman-banner-content {
        margin: 0 80px;
    }
}

@media only screen and (max-width: 1024px) {
    .apartman-banner-content {
        margin: 0 50px;
    }
}

@media only screen and (max-width: 768px) {
    .apartman-banner-content {
        margin: 0 25px;
    }
}

@media only screen and (max-width: 450px) {
    .apartman-banner-content {
        margin: 0 15px;
    }
}

@media only screen and (max-width: 426px) {
    .apartman-banner-content {
        margin: 0 15px;
    }
}

@media only screen and (max-width: 376px) {
    .apartman-banner-content {
        margin: 0 10px;
    }
}

@media only screen and (max-width: 321px) {
    .apartman-banner-content {
        margin: 0 5px;
    }
}