.open-concept-component {
    padding: 2rem 0;
    position: relative;
    overflow: hidden;
}

.open-concept-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5px;
    row-gap: 10px;
}

.open-concept-title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.open-concept-title.show {
    opacity: 1;
}

.open-concept-title h1 {
    font-family: 'Raleway', sans-serif;
    padding-bottom: 2rem;
    font-weight: 100;
}

.open-concept-column {
    width: 100%;
    height: 300px;
}

.open-concept-column:nth-child(1).show {
    transform: translateX(0);
    opacity: 1;
}

.open-concept-column:nth-child(2).show {
    transform: translateX(0);
    opacity: 1;
}

.open-concept-column:nth-child(3).show {
    transform: translateX(0);
    opacity: 1;
}

.open-concept-column:nth-child(4).show {
    transform: translateX(0);
    opacity: 1;
}

.open-concept-column:nth-child(2) {
    text-align: right;
}

.open-concept-column img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.occ-text {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .open-concept-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .open-concept-column:nth-child(2) {
        height: auto;
        text-align: left;
    }

    .open-concept-column:nth-child(3) {
        height: auto;
        text-align: left;
    }
}