.about-us-dark {
    width: 100%;
    height: calc(100vh - 300px);
    background-color: var(--main_text_color);
    position: relative;
}

.about-us-dark img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    opacity: .25;
}

.about-us-dark-content {
    width: auto;
    height: 100%;
    margin: 0 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 30px;
    z-index: 10;
    position: relative;
}

.about-us-dark-column {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-us-dark-column:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-us-dark-column .about-us-dark-hall {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--hover_color);
    border-radius: 5px;
    position: relative;
    box-shadow: 0 8px 8px 0 rgba(0,0,0,0.1);
}

.about-us-dark-column .about-us-dark-hall.show {
    opacity: 1;
    transform: translateX(0);
}

.about-us-dark-column .about-us-dark-hall h1.show {
    opacity: 1;
    transform: translateX(0);
    transition-delay: .2s;
}

.about-us-dark-star {
    position: absolute;
    top: -60px;
    left: -30px;
    z-index: 10;
    width: 100%;
    height: 120px;
    background-color: var(--main_color);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-10deg);
    box-shadow: 0 8px 8px 0 rgba(0,0,0,0.1);
}

.about-us-dark-star.show {
    opacity: 1;
    transition-delay: .4s;
}

.about-us-dark-star h1 {
    color: var(--pink_color) !important;
}

.about-us-dark-column:nth-child(2) h2 {
    text-align: right;
    font-size: 1.8rem;
}

.about-us-dark-column:nth-child(2) h2.show {
    opacity: 1;
    transform: translateX(0);
}

.about-us-dark-column .about-us-dark-hall h1 {
    font-size: 3rem;
    color: var(--main_color);
    text-align: center;
}

@media only screen and (max-width: 1440px) {
    .about-us-dark-content {
        margin: 0 80px;
    }
}

@media only screen and (max-width: 1024px) {
    .about-us-dark-content {
        margin: 0 50px;
    }

    .about-us-dark-content {
        grid-template-columns: repeat(1, 1fr);
    }

    .about-us-dark {
        height: auto;
    }

    .about-us-dark-column {
        height: auto;
        padding: 30px 0;
    }

    .about-us-dark-star {
        top: -75px;
    }

    .about-us-dark-column:nth-child(2) h2 {
        text-align: center;
    }

    .about-us-dark-column:nth-child(2) {
        height: auto;
    }
}

@media only screen and (max-width: 768px) {
    .about-us-dark-content {
        margin: 0 25px;
    }

    .about-us-dark-column:nth-child(2) h2 {
        font-size: 1.4rem;
    }

    .about-us-dark-star {
        height: 80px;
    }

    .about-us-dark-star h1 {
        text-align: center;
        font-size: 1.6rem !important;
    }

    .about-us-dark-column .about-us-dark-hall {
        width: 90%;
        height: 180px;
    }
}

@media only screen and (max-width: 450px) {
    .about-us-dark-content {
        margin: 0 15px;
    }

    .about-us-dark-column .about-us-dark-hall {
        width: 100%;
    }

    .about-us-dark-star {
        top: -75px;
        left: -5px;
    }

    .about-us-dark-column:nth-child(2) h2 {
        text-align: left;
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 426px) {
    .about-us-dark-content {
        margin: 0 15px;
    }

    .about-us-dark-column .about-us-dark-hall {
        width: 100%;
    }

    .about-us-dark-star {
        top: -75px;
        left: -5px;
    }

    .about-us-dark-column:nth-child(2) h2 {
        text-align: left;
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 376px) {
    .about-us-dark-content {
        margin: 0 10px;
    }
}

@media only screen and (max-width: 321px) {
    .about-us-dark-content {
        margin: 0 5px;
    }

    .about-us-dark-star {
        top: -75px;
        left: 0px;
    }
}