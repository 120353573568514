.movie-star-vectors {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 20px;
    padding: 3rem 0;
}

.movie-star-vector-column:nth-child(1) {
    width: 100%;
    height: 480px;
    transition: all 1s ease;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.movie-star-vector-column:nth-child(2) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
}

.movie-star-vector-column:nth-child(2) img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transform: rotate(45deg);
    opacity: .25;
}

.movie-star-vector-column h2 {
    color: var(--silver_color);
}

.movie-star-vector-column:nth-child(2) h2 {
    font-size: 1.3rem;
}

.movie-star-vector-column:nth-child(2) h1 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.movie-star-vector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
    padding: 0.5rem 0;
}

.movie-star-vector.pink-star h1 {
    color: var(--pink_color);
}

@media only screen and (max-width: 768px) {
    .movie-star-vectors {
        grid-template-columns: repeat(1, 1fr);
    }

    .movie-star-vector-column:nth-child(1) {
        height: 380px;
    }

    .movie-star-vector {
        padding: 0;
    }
}