.footer-section {
    width: 100%;
    height: auto;
    overflow-x: hidden;
}

.footer-content {
    margin: 0 100px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 40px 0;
    column-gap: 15px;
    row-gap: 15px;
}

/* FOOTER COLUMN 1 */
.footer-column-1 {
    text-align: left;
}

.footer-column-1 h3 {
    min-height: 70px;
}

.footer-media {
    display: flex;
    margin-top: 40px;
    margin-bottom: 10px;
}

.footer-media .footer-svg {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    text-decoration: none;
    color: var(--main_text_color);
    background-color: var(--main_color);
    border-radius: 8px;
    transition: all .5s ease;
}

.footer-media .footer-svg:hover {
    background-color: var(--main_text_color);
    color: var(--main_color);
}

.footer-media .footer-svg svg {
    font-size: 1.2rem;
}

.footer-media .footer-svg:nth-child(1) {
    margin-right: 10px;
}

.footer-location-phone {
    display: flex;
    flex-direction: column;
    width: auto;
}

.footer-location-phone .footer-svg {
    display: flex;
    align-items: center;
    padding: 5px;
    text-decoration: none;
    color: var(--main_text_color);
    background-color: var(--main_color);
    border-radius: 8px;
    transition: all .5s ease;
    max-width: 250px;
    width: auto;
}

.footer-location-phone .footer-svg:nth-child(1) {
    margin-bottom: 10px;
}

.footer-location-phone .footer-svg:hover {
    background-color: var(--main_text_color);
    color: var(--main_color);
}

.footer-location-phone .footer-svg svg {
    font-size: 1.2rem;
    margin-right: 10px;
}

/* FOOTER COLUMN 2 */
.footer-column-2 {
    text-align: center;
}

.footer-open-time {
    display: flex;
    justify-content: space-between;
    min-height: 70px;
    height: 70px;
    margin-bottom: 40px;
}

.footer-logo {
    width: 100%;
    height: 40px;
}

.footer-logo img {
    width: auto;
    height: 40px;
}

/* FOOTER COLUMN 3 */
.footer-column-3 {
    text-align: right;
}

.footer-column-3 h3 {
    min-height: 70px;
    height: 70px;
    margin-bottom: 40px;
}

.footer-column-3 h3 a {
    text-decoration: none;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: var(--main_text_color);
}

.footer-column-3 a {
    text-decoration: none;
    color: var(--main_text_color);
    font-size: 1.2rem;
}

.footer-column h1 {
    margin-bottom: 40px;
}

/* FOOTER COPYRIGHT */
.footer-copyright {
    background-color: var(--hover_color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
}

.footer-copyright p {
    color: var(--main_color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.footer-copyright p span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
}

.footer-column-1.show {
    opacity: 1;
    transform: translateX(0);
}

.footer-column-2.show {
    opacity: 1;
}

.footer-column-3.show {
    opacity: 1;
    transform: translateX(0);
}

.footer-copyright.show {
    opacity: 1;
    transition-delay: .2s;
}

@media only screen and (max-width: 1440px) {
    .footer-content {
        margin: 0 80px;
    }
}

@media only screen and (max-width: 1024px) {
    .footer-content {
        margin: 0 50px;
    }

    .footer-column-3 h3 a {
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 768px) {
    .footer-content {
        margin: 0 25px;
        grid-template-columns: repeat(2, 1fr);
    }

    .footer-column-2 {
        text-align: right;
    }

    .footer-column-3 {
        grid-column: span 2;
        text-align: center;
    }
}

@media only screen and (max-width: 450px) {
    .footer-content {
        margin: 0 15px;
        grid-template-columns: repeat(1, 1fr);
    }

    .footer-column-3 {
        grid-column: span 1;
        text-align: left;
    }

    .footer-column-2 {
        text-align: left;
    }

    .footer-column-1 h3 {
        min-height: auto;
    }

    .footer-column h1 {
        margin-bottom: 10px !important;
    }

    .footer-media {
        margin-top: 10px !important;
    }

    .footer-open-time {
        min-height: 0 !important;
        height: auto;
        margin-bottom: 15px;
    }

    .footer-column-3 h3 {
        min-height: 0;
        height: auto;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 426px) {
    .footer-content {
        margin: 0 15px;
        grid-template-columns: repeat(1, 1fr);
    }

    .footer-column-3 {
        grid-column: span 1;
        text-align: left;
    }

    .footer-column-2 {
        text-align: left;
    }

    .footer-column-1 h3 {
        min-height: auto;
    }

    .footer-column h1 {
        margin-bottom: 10px !important;
    }

    .footer-media {
        margin-top: 10px !important;
    }

    .footer-open-time {
        min-height: 0 !important;
        height: auto;
        margin-bottom: 15px;
    }

    .footer-column-3 h3 {
        min-height: 0;
        height: auto;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 376px) {
    .footer-content {
        margin: 0 10px;
    }
}

@media only screen and (max-width: 321px) {
    .footer-content {
        margin: 0 5px;
    }

    .footer-copyright p {
        font-size: 0.9rem;
    }
}