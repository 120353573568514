.gallery-component {
    position: relative;
    margin-bottom: 3rem;
    overflow-x: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery-component-content {
    max-width: 1320px;
    position: relative;
    width: 100%;
}

.gallery-component.show {
    opacity: 1;
    transform: translateX(0);
}

.gallery-component .gallery-content {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: calc((100% / 5));
    overflow: hidden;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
}

.gallery-component .gallery-content::-webkit-scrollbar {
    display: none;
}

.gallery-component button:first-child {
    left: 0;
}

.gallery-component button:last-child {
    right: 0;
}

.gallery-btn {
    position: absolute;
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: var(--main_color);
    text-align: center;
    line-height: 40px;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
}

@media only screen and (max-width: 1024px) {
    .gallery-component .gallery-content {
        grid-auto-columns: calc(100% / 4);
    }
}

@media only screen and (max-width: 768px) {
    .gallery-component .gallery-content {
        grid-auto-columns: calc(100% / 3);
    }
}

@media only screen and (max-width: 450px) {
    .gallery-component .gallery-content {
        grid-auto-columns: calc(100% / 2);
    }
}

@media only screen and (max-width: 426px) {
    .gallery-component .gallery-content {
        grid-auto-columns: calc(100% / 2);
    }
}

@media only screen and (max-width: 376px) {
    .gallery-component .gallery-content {
        grid-auto-columns: calc(100% / 1);
    }
}