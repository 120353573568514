.about-us-component {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 2rem 0;
    overflow: hidden;
}

.about-us-component.show {
    opacity: 1;
}

.about-us-content {
    width: 100%;
    height: 420px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 15px;
    row-gap: 30px;
}

.about-us-column-1 {
    width: 100%;
}

.about-us-column-1.show {
    opacity: 1;
    transform: translateX(0);
}

.about-us-column-1 img {
    width: 100%;
    height: 420px;
    object-fit: cover;
}

.about-us-column-2 {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
}

.about-us-column-2 h1 {
    margin-bottom: 40px;
}

.about-us-column-2 h1.show {
    transform: translateX(0);
    opacity: 1;
}

.about-us-column-2 a {
    width: 300px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main_text_color);
    color: var(--main_color);
    font-size: 1.2rem;
    text-decoration: none;
}

.about-us-column-2.show {
    opacity: 1;
    transform: translateX(0);
}

.about-us-column-2 p {
    text-align: right;
    letter-spacing: 2px;
    font-weight: 500;
    color: var(--hover_color);
    z-index: 2;
    margin-bottom: 40px;
}

@media only screen and (max-width: 768px) {
    .about-us-content {
        grid-template-columns: repeat(1, 1fr);
        height: auto;
    }

    .about-us-column-2 {
        align-items: flex-start;
    }

    .about-us-column-2 p {
        text-align: left;
        letter-spacing: 1px;
    }
}

@media only screen and (max-width: 450px) {
    .about-us-column-2 {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 426px) {
    .about-us-column-2 {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}