.ms-component {
    background: linear-gradient(to bottom right , var(--pink_color), var(--silver_color));
    width: 100%;
    height: 260px;
    position: relative;
}

.ms-component-overlay {
    width: 100%;
    height: 100%;
    opacity: 0.2;
}

.ms-component-overlay img:nth-child(1) {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ms-component-overlay img:nth-child(2) {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .8;
    position: absolute;
    top: 0;
    left: 0;
}

.ms-content {
    position: absolute;
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    margin: 0 100px;
}

.ms-content h3 {
    color: var(--main_color);
    text-align: center;
    margin-bottom: 40px;
}

.ms-content a {
    width: 300px;
    height: 60px;
    background-color: var(--main_color);
    color: var(--pink_color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1.2rem;
}

@media only screen and (max-width: 1440px) {
    .ms-content {
        margin: 0 80px;
    }
}

@media only screen and (max-width: 1024px) {
    .ms-content {
        margin: 0 50px;
    }
}

@media only screen and (max-width: 768px) {
    .ms-content {
        margin: 0 25px;
    }
}

@media only screen and (max-width: 450px) {
    .ms-content {
        margin: 0 15px;
    }
}

@media only screen and (max-width: 426px) {
    .ms-content {
        margin: 0 15px;
    }
}

@media only screen and (max-width: 376px) {
    .ms-content {
        margin: 0 10px;
    }
}

@media only screen and (max-width: 321px) {
    .ms-content {
        margin: 0 5px;
    }
}