.gallery-section-slider {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.8);
    z-index: 9999;
    visibility: hidden;
    transition: opacity .3s ease, visibility .3s ease, transform .4s ease-in-out;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
}

.gallery-section-slider .close-svg {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    color: var(--main_color);
    cursor: pointer;
}

.gallery-section-slider img {
    width: auto;
    max-width: 100%;
    max-height: 100vh;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.gallery-section-slider.isOpen {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.gallery-section-slider .gss-btn {
    border: 0;
    outline: 0;
    background-color: var(--main_color);
    opacity: 0.5;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transition: all .3s ease;
}

.gallery-section-slider .gss-btn:hover {
    opacity: 1;
}

.gallery-section-slider .gss-btn svg {
    color: var(--gold_color);
}

.gallery-section-slider .gss-prev {
    left: 20px;
}

.gallery-section-slider .gss-next {
    right: 20px;
}

@media only screen and (max-width: 768px) {
    .gallery-section-images {
        grid-template-columns: repeat(3, 1fr);
    }

    .gallery-section-slider .gss-prev {
        left: 10px;
    }
    
    .gallery-section-slider .gss-next {
        right: 10px;
    }
}

@media only screen and (max-width: 450px) {
    .gallery-section-images {
        grid-template-columns: repeat(2, 1fr);
    }

    .gallery-section-slider .gss-prev {
        left: 5px;
    }
    
    .gallery-section-slider .gss-next {
        right: 5px;
    }
}

@media only screen and (max-width: 426px) {
    .gallery-section-images {
        grid-template-columns: repeat(2, 1fr);
    }

    .gallery-section-slider .gss-prev {
        left: 5px;
    }
    
    .gallery-section-slider .gss-next {
        right: 5px;
    }
}

@media only screen and (max-width: 376px) {
    .gallery-section-images {
        grid-template-columns: repeat(1, 1fr);
    }
}