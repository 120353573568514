.weddings-open-concept {
    width: 100%;
    position: relative;
    height: 280px;
    background-color: var(--main_text_color);
    padding: 3rem 0;
    overflow-x: hidden;
}

.weddings-open-concept img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    opacity: .25;
}

.weddings-open-concept-content {
    width: auto;
    height: 100%;
    margin: 0 100px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.weddings-open-concept-content h1 {
    text-transform: capitalize;
    margin-bottom: 40px;
}

.weddings-open-concept-content h1.show {
    opacity: 1;
    transform: translateX(0);
}

.weddings-open-concept-content p {
    color: var(--main_color);
    letter-spacing: 1px;
    font-weight: 800;
}

.weddings-open-concept-content p.show {
    opacity: 1;
    transform: translateX(0);
}

@media only screen and (max-width: 1440px) {
    .weddings-open-concept-content {
        margin: 0 80px;
    }
}

@media only screen and (max-width: 1024px) {
    .weddings-open-concept-content {
        margin: 0 50px;
    }
}

@media only screen and (max-width: 768px) {
    .weddings-open-concept-content {
        margin: 0 25px;
    }

    .weddings-open-concept-content p {
        letter-spacing: normal;
        font-weight: 500;
    }

    .weddings-open-concept {
        height: auto;
    }
}

@media only screen and (max-width: 450px) {
    .weddings-open-concept-content {
        margin: 0 15px;
    }

    .weddings-open-concept-content p {
        letter-spacing: normal;
        font-weight: 300;
        text-align: left;
    }
}

@media only screen and (max-width: 426px) {
    .weddings-open-concept-content {
        margin: 0 15px;
    }

    .weddings-open-concept-content p {
        letter-spacing: normal;
        font-weight: 300;
        text-align: left;
    }
}

@media only screen and (max-width: 376px) {
    .weddings-open-concept-content {
        margin: 0 10px;
    }
}

@media only screen and (max-width: 321px) {
    .weddings-open-concept-content {
        margin: 0 5px;
    }
}