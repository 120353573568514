.gallery-cards-component {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
    column-gap: 10px;
    margin: 3rem 0;
}

.gallery-cards-card {
    width: 100%;
    height: 380px;
    position: relative;
}

.gallery-cards-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gallery-cards-card-overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .gallery-cards-component {
        grid-template-columns: repeat(2, 1fr);
        height: auto;
    }
}

@media only screen and (max-width: 450px) {
    .gallery-cards-component {
        grid-template-columns: repeat(1, 1fr);
        height: auto;
    }
}

@media only screen and (max-width: 426px) {
    .gallery-cards-component {
        grid-template-columns: repeat(1, 1fr);
        height: auto;
    }
}