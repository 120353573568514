.contact-hero {
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 1000px;
    overflow-x: hidden;
}

.contact-hero.show {
    opacity: 1;
}

.contact-hero img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contact-hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, rgba(0,0,0,0.4), transparent), linear-gradient(to top left, rgba(0,0,0,0.4), transparent);
}

.contact-hero-content {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
    top: 0;
}

.contact-hero-margin {
    margin: 0 100px;
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contact-hero-margin h1 {
    margin-bottom: 40px;
}

.contact-hero-margin h1.show {
    opacity: 1;
    transition-delay: .2s;
}

.contact-hero-box {
    width: 40%;
    height: 120px;
    background-color: rgba(0,0,0,0.4);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}

.contact-hero-box.show {
    opacity: 1;
    transition-delay: .4s;
}

.contact-hero-box p {
    color: var(--main_color);
    letter-spacing: 1.5px;
    text-align: center;
    font-size: 22px;
}

@media only screen and (max-width: 1440px) {
    .contact-hero-margin {
        margin: 0 80px;
    }
}

@media only screen and (max-width: 1024px) {
    .contact-hero-margin {
        margin: 0 50px;
    }

    .contact-hero-box {
        width: 60%;
    }
}

@media only screen and (max-width: 768px) {
    .contact-hero-margin {
        margin: 0 25px;
    }

    .contact-hero-box {
        width: 80%;
    }

    .contact-hero-box p {
        font-size: 18px;
    }
}

@media only screen and (max-width: 450px) {
    .contact-hero-margin {
        margin: 0 15px;
    }

    .contact-hero-box p {
        font-size: 16px;
    }
}

@media only screen and (max-width: 426px) {
    .contact-hero-margin {
        margin: 0 15px;
    }

    .contact-hero-box p {
        font-size: 16px;
    }
}

@media only screen and (max-width: 376px) {
    .contact-hero-margin {
        margin: 0 10px;
    }
}

@media only screen and (max-width: 321px) {
    .contact-hero-margin {
        margin: 0 5px;
    }
}