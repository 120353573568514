.not-found {
    width: 100%;
    height: 100vh;
    background: linear-gradient(to bottom right, var(--main_color), var(--main_text_color));
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.not-found img {
    width: 200px;
    height: auto;
    margin-bottom: 20px;
}

.not-found h1 {
    margin-bottom: 20px;
}

.not-found h3 a {
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    height: 60px;
    background-color: transparent;
    border: 2px solid var(--hover_color);
    padding: 10px;
    color: var(--hover_color);
}