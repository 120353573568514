.movie-star-component {
    width: 100%;
    height: auto;
    background: linear-gradient(to bottom right, var(--pink_color), var(--silver_color));
    position: relative;
    z-index: 1;
    padding: 3rem 0;
    overflow: hidden;
}

.movie-star-component .msc-dec:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    object-fit: cover;
    z-index: 2;
}

.movie-star-component .msc-dec:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    object-fit: cover;
    z-index: 2;
}

.movie-star-component-content {
    margin: 0 100px;
}

.movie-star-component-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 15px;
    row-gap: 60px;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.movie-star-component-column-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.movie-star-component-column-1 a {
    background-color: var(--main_color);
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 10px 0;
    color: var(--pink_color);
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1.2rem;
    text-transform: uppercase;
}

.movie-star-component-column-1 h1 {
    font-family: 'Montagu Slab', serif !important;
}

.movie-star-component-column-1 .msc-64 {
    font-size: 64px !important;
    color: var(--main_color) !important;
    margin-bottom: 20px;
}

.movie-star-component-column-1 .msc-96 {
    font-size: 96px !important;
    color: var(--main_text_color);
}

.movie-star-component-column-1 .msc-64.show {
    transform: translateX(0);
    opacity: 1;
    transition-delay: .2s;
}

.movie-star-component-column-1 .msc-96.show {
    transform: translateX(0);
    opacity: 1;
    transition-delay: .4s;
}

.movie-star-component-column-1 .msc-btn.show {
    transform: translateX(0);
    opacity: 1;
    transition-delay: .6s;
}

/* MOVIE STAR COMPONENT SLIDER */
.movie-star-component-column-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(10deg);
    position: relative;
}

.movie-star-box {
    position: absolute;
    top: 40px;
    left: -15px;
    background-color: var(--main_color);
    width: 240px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    transform: rotate(-20deg);
    border-radius: 5px;
    padding: 10px;
}

.movie-star-box p {
    color: var(--pink_color);
    font-weight: bold;
}

.movie-star-component-slide-div {
    width: 160px;
    background-repeat: no-repeat;
    background-size: 480px;
    border-radius: 60px;
    height: 480px;
    transition: 1s ease-in-out;
    overflow: hidden;
}

.movie-star-component-slide-div:nth-child(1).show {
    opacity: 1;
    transform: translateX(0);
    transition-delay: .2s;
}

.movie-star-component-slide-div:nth-child(2).show {
    opacity: 1;
    transform: translateX(0);
    transition-delay: .4s;
}

.movie-star-component-slide-div:nth-child(3).show {
    opacity: 1;
    transform: translateX(0);
    transition-delay: .6s;
}

.movie-star-component-slide-div:nth-child(1){
    background-position: 0 0;
}

.movie-star-component-slide-div:nth-child(2){
    background-position: -160px 0;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.movie-star-call {
    width: 90%;
    height: 80px;
    background-color: #fff;
    border-radius: 40px;
    cursor: pointer;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
}

.movie-star-gold {
    background-color: var(--gold_color);
    width: 80%;
    height: 60px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.movie-star-gold svg {
    color: var(--main_color);
    font-size: 1.6rem;
}

.movie-star-component-slide-div:nth-child(3){
    background-position: -320px 0;
}

.movie-star-box.show {
    opacity: 1;
    transform: translateX(0);
}

@media only screen and (max-width: 1440px) {
    .movie-star-component-column-1 .msc-64 {
        font-size: 56px !important;
    }

    .movie-star-component-content {
        margin: 0 80px;
    }
    
    .movie-star-component-column-1 .msc-96 {
        font-size: 64px !important;
    }

    .movie-star-box {
        top: 15px;
        left: 0;
    }
}

@media only screen and (max-width: 1024px) {
    .movie-star-component-content {
        margin: 0 50px;
    }

    .movie-star-box {
        top: 0;
        left: -30px;
    }

    .movie-star-component-column-1 .msc-64 {
        font-size: 48px !important;
    }

    .movie-star-component-column-1 .msc-96 {
        font-size: 56px !important;
    }

    .movie-star-component-slide-div {
        width: 140px;
        height: 445px;
    }

    .movie-star-component-slide-div:nth-child(1){
        background-position: 0 0;
    }
    
    .movie-star-component-slide-div:nth-child(2){
        background-position: -140px 0;
        margin: 0 15px;
    }
    
    .movie-star-component-slide-div:nth-child(3){
        background-position: -280px 0;
    }
}

@media only screen and (max-width: 768px) {
    .movie-star-component-grid {
        grid-template-columns: repeat(1, 1fr);
        height: auto;
    }

    .movie-star-box {
        top: 0;
        left: 70px;
        transform: rotate(-10deg);
    }

    .movie-star-component-content {
        margin: 0 25px;
    }

    .movie-star-component-column-1 {   
        align-items: center;
    }

    .movie-star-component-column-1 h1 {
        text-align: center;
    }

    .movie-star-component-column-2 {
        transform: rotate(0deg);
    }

    .movie-star-component-slide-div {
        width: 120px;
        height: 415px;
        border-radius: 0;
    }

    .movie-star-component-slide-div:nth-child(1){
        background-position: 0 0;
    }
    
    .movie-star-component-slide-div:nth-child(2){
        background-position: -120px 0;
        margin: 0;
    }
    
    .movie-star-component-slide-div:nth-child(3){
        background-position: -240px 0;
    }

    .movie-star-component-column-1 .msc-64 {
        font-size: 36px !important;
    }

    .movie-star-component-column-1 .msc-96 {
        font-size: 48px !important;
    }
}

@media only screen and (max-width: 450px) {
    .movie-star-component-content {
        margin: 0 15px;
    }

    .movie-star-component-column-1 h1 {
        font-size: 1.5em !important;
    }

    .movie-star-component-column-1 h1 {
        text-align: left;
    }

    .movie-star-box {
        top: -20px;
        left: 0;
        transform: rotate(-5deg);
    }
}

@media only screen and (max-width: 426px) {
    .movie-star-component-content {
        margin: 0 15px;
    }

    .movie-star-component-column-1 h1 {
        font-size: 1.5em !important;
    }

    .movie-star-component-column-1 h1 {
        text-align: left;
    }

    .movie-star-box {
        top: -20px;
        left: 0;
        transform: rotate(-5deg);
    }
}

@media only screen and (max-width: 376px) {
    .movie-star-component-content {
        margin: 0 10px;
    }

    .movie-star-component-slide-div {
        width: 110px;
    }

    .movie-star-component-slide-div:nth-child(1){
        background-position: 0 0;
    }
    
    .movie-star-component-slide-div:nth-child(2){
        background-position: -110px 0;
        margin: 0;
    }
    
    .movie-star-component-slide-div:nth-child(3){
        background-position: -220px 0;
    }
}

@media only screen and (max-width: 321px) {
    .movie-star-component-content {
        margin: 0 5px;
    }

    .movie-star-component-slide-div {
        width: 100px;
    }

    .movie-star-component-slide-div:nth-child(1){
        background-position: 0 0;
    }
    
    .movie-star-component-slide-div:nth-child(2){
        background-position: -100px 0;
        margin: 0;
    }
    
    .movie-star-component-slide-div:nth-child(3){
        background-position: -200px 0;
    }
}